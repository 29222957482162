import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstaComponent } from './layouts/insta/insta.component';
import { SiteComponent } from './layouts/site/site.component';

const routes: Routes = [
  {path:'', component:SiteComponent},
  {path:':id',component:InstaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
