import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BookData, pageSettings, Price } from 'src/app/shared/interfaces';
import { MetaService } from 'src/app/shared/meta.service';



@Component({
  selector: 'app-insta',
  templateUrl: './insta.component.html',
  styleUrls: ['./insta.component.scss']
})
export class InstaComponent implements OnInit {
  setupMode = false
  page = true
  settings: pageSettings={}
  dayOff: any
  constructor(
    public dialog: MatDialog,
    private metaServise: MetaService,
    private _snackBar: MatSnackBar,
    private elRef: ElementRef,
    private titleSevice: Title,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // this.settings = {
    //   _id: "",
    //   sitePath: "",
    //   siteTitle: "",
    //   bgColor: "",
    //   bgImage: "",
    //   buttonColor: "",
    //   fontFamily: "",
    //   textColor: "",
    //   phoneNumber: "",
    //   watsappNumber: "",
    //   viberNumber: "",
    //   vkLink: "",
    //   snackBarMessage: "",
    //   description: "",
    //   slogan: "",
    //   imageSrc: ""
    // }
  }



  ngOnInit() {

    this.route.params.subscribe((params: Params) => {

      this.metaServise.getPageSettings(params.id).subscribe(set => {
        console.log(set)
        if (set) {
          this.settings = set
          this.changeStyle('--bg-color', this.settings.bgColor)
          this.changeStyle('--button-color', this.settings.buttonColor)
          this.titleSevice.setTitle(this.settings.siteTitle ? this.settings.siteTitle : "BookingMe")
          this.changeStyle('--font-family', this.settings.fontFamily)

          console.log(this.settings.fontFamily)


          this.metaServise.getDayOff(params.id).subscribe(dOff => {
            this.dayOff = dOff
          })
        }
        else {
          this.page = false
          this.router.navigate(['/'])
        }
      })



    });
  }
  changeStyle(prop: any, style: any) {
    this.elRef.nativeElement.style.setProperty(prop, style)
  }
  openBookDialog() {
    const dialogRef = this.dialog.open(BookDialog, {
      width: '400px',
      data: {
        dayOff: this.dayOff
      },

    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        const start = new Date(result.date)
        start.setHours(result.hour)
        start.setMinutes(result.minute)

        console.log(start)

        const toWrite = {
          allDay: false,
          pause: false,
          color: "red",
          confirmed: false,
          endTime: "",
          phone: result.phone,
          service: result.service,
          startTime: start.getTime(),

          timing: 0,
          title: result.name,
          name: result.name,
          hour: result.hour,
          minute: result.minute,
          date: result.date,

        }
        console.log(result)
        this.metaServise.newBook(this.router.url, toWrite).subscribe(res => {
          this.openSnackBar()
        })
      }

    });
  }

  openSnackBar() {
    this._snackBar.open(this.settings.snackBarMessage ? this.settings.snackBarMessage : "", 'ОК', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  openPriceDialog() {
    const dialogRef = this.dialog.open(PriceDialog, {
      width: '400px',
      data: {}
    })
  }


}


@Component({
  selector: 'price-dialog',
  templateUrl: 'price-dialog.html',
})
export class PriceDialog implements OnInit {
  price: Price[] = [];
  form = new FormGroup({
    nameControl: new FormControl('', Validators.required),
    phoneControl: new FormControl('', Validators.required),
    dateControl: new FormControl('', Validators.required),
    hhControl: new FormControl('', Validators.required),
    mmControl: new FormControl('', Validators.required),
    servControl: new FormControl('', Validators.required)
  })

  constructor(
    public dialogRef: MatDialogRef<PriceDialog>,
    private router: Router,
    private meta: MetaService,

    @Inject(MAT_DIALOG_DATA) public data: any

  ) {

    let id = this.router.url.slice(1, this.router.url.length)
    this.meta.getPrice(id).subscribe(price =>
      this.price = price
    )
  }
  ngOnInit() {
    // let formattedDate=(d:Date)=>{
    //   return [d.getDate(), d.getMonth()+1, d.getFullYear()]
    //       .map(n => n < 10 ? `0${n}` : `${n}`).join('/')}
   
   
    //       alert(formattedDate(new Date()))

  }
   


  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'book-dialog',
  templateUrl: 'book-dialog.html',
})
export class BookDialog implements OnInit {
  minDate: Date

  services: Price[]
  form = new FormGroup({
    nameControl: new FormControl('', Validators.required),
    phoneControl: new FormControl('', Validators.required),
    dateControl: new FormControl('', Validators.required),
    hhControl: new FormControl('', Validators.required),
    mmControl: new FormControl('', Validators.required),
    servControl: new FormControl('', Validators.required)
  })

  constructor(
    public dialogRef: MatDialogRef<BookDialog>,
    private meta: MetaService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: BookData,

  ) {


    this.services = []
    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);

    this.meta.getPrice(this.router.url).subscribe(price =>
      this.services = price
    )

  }
  ngOnInit(): void {
    console.log(this.data.dayOff)
  }
  myFilter = (d: any): boolean => {
    let result: boolean
    if (d&&this.data.dayOff) {
      result = !this.data.dayOff.includes(d.toISOString())
      console.log(result)
    } else{
      result=true
    }

    return result
  }

  timeHH = ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19']
  timeMM = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']


  onNoClick() {
    this.dialogRef.close();
  }
}

