<div class="container">
    <div class="wrapper">
        <div >
            {{settings?settings.siteTitle:""}}
        </div>
        <div class="top">
            
        </div>
        <div class="photo">
            <!-- <img src={{settings.imageSrc}}> -->
            
            <div *ngIf="settings.imageSrc">
                <img src={{settings.imageSrc}}>
            </div>
            <div *ngIf="!settings.imageSrc">
                <img src="..//../../assets/logo/logo.png">
            </div>
        </div>
        <div >{{settings?settings.slogan:""}}
        </div>
        <div c>
            <div>
                {{settings?settings.description:""}}
            </div>
            
            
        </div>
        <div class="sitename">
        @{{settings.sitePath}}
        </div>
        <div class="button-wrapper">

            <button (click)='openBookDialog()' class="btn">
                Записаться
            </button>


            <button (click)='openPriceDialog()' class="btn">
                Прайс
            </button>

        </div>

        <div class="call-buttons" *ngIf="settings">

            <a href="https://wa.me/{{settings.watsappNumber}}" class="call-button" *ngIf="settings.watsappNumber">
                <img src="..//../../assets/icons/WhatsApp_Logo_6.png">
            </a>

            <a href="tel:{{settings.phoneNumber}}" class="call-button" *ngIf="settings.phoneNumber">
                <img src="..//../../assets/icons/call.png">
            </a>

            <a href="viber://chat?number=%2{{settings.viberNumber}}" class="call-button" *ngIf="settings.viberNumber">
                <img src="..//..//..//assets/icons/viber.png">
            </a>

            <a href="https://vk.com/{{settings.vkLink}}" class="call-button" *ngIf="settings.vkLink">
                <img src="..//../../assets/icons/VK_Compact_Logo.png">
            </a>
        </div>
    </div>
</div>