import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BookData, FbCreateResponse, pageSettings, Price } from './interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private http: HttpClient) { }
 
  // getBooks():Observable<BookData[]>{
  //   return this.http.get<BookData[]>(`${environment.fbDbUrl}/books.json`)
  //   .pipe(map((response: { [key: string]: any }) => {
  //     return Object
  //       .keys(response)
  //       .map(key => ({
  //         ...response[key],
  //         e_id: key,
  //         date: new Date(response[key].date)
  //       }))
  //   }))
  // }

  newBook(page:string, book:any):Observable<BookData>{
    return this.http.post<BookData>(`${environment.fbDbUrl}/pages/${page}/booksFromSite.json`, book)
  }

     getPrice(page:string):Observable<Price[]>{
     return this.http.get<Price[]>(`${environment.fbDbUrl}/pages/${page}/price.json`)
     .pipe(map((response: { [key: string]: any }) => {
      return Object
        .keys(response)
        .map(key => ({
          ...response[key],
          e_id: key
         }))
    }))
   }
   getPageSettings(page:pageSettings):Observable<pageSettings>{
     
    return this.http.get<pageSettings>(`${environment.fbDbUrl}/pages/${page}/pageSettings.json`)
   }
   getDayOff(page:string):Observable<any[]> {
    return this.http.get<any[]>(`${environment.fbDbUrl}/pages/${page}/dayOff.json`)
      .pipe(map((response: { [key: string]: any }) => {
      return Object
        .keys(response)
        .map(key => (
            response[key]
        ))
    }))
    
   }
}
