<h1 mat-dialog-title>Записаться</h1>
<div mat-dialog-content>
  <form [formGroup]="form">

    <mat-form-field>
      <mat-label>Имя</mat-label>
      <input matInput [(ngModel)]="data.name" formControlName="nameControl" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Телефон</mat-label>
      <input matInput [(ngModel)]="data.phone" type="number" formControlName="phoneControl" required minlength="7">

    </mat-form-field>
      <mat-label>Услуга</mat-label>
      <mat-selection-list formControlName="servControl" required [(ngModel)]="data.service">
        <mat-list-option *ngFor="let service of services"  style="font-size: small; height: unset;" [value]="service">
          {{service.name}}
        </mat-list-option>
      </mat-selection-list>
    
    <mat-form-field>

      <mat-label>Дата</mat-label>
      <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker" [min]="minDate" [(ngModel)]="data.date" formControlName="dateControl" 
      
        required >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker touchUi disabled="false"></mat-datepicker>
    </mat-form-field>
    <div style="display: flex; justify-content: space-between; width: 80%;">
      <mat-label>Время</mat-label>
      <mat-form-field class="time" [style.width.rem]=4>
        <mat-select formControlName="hhControl" [(ngModel)]="data.hour">
          <mat-option *ngFor='let hh of timeHH' [value]="hh" >
            {{hh}}
          </mat-option>
        </mat-select>
        <span matSuffix>Час</span>
      </mat-form-field>
      <mat-form-field class="time" [style.width.rem]=4>
        <mat-select formControlName="mmControl" [(ngModel)]="data.minute">
          <mat-option *ngFor="let mm of timeMM" [value]="mm" >
            {{mm}}
          </mat-option>
        </mat-select>
        <span matSuffix>Мин</span>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Отмена</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial [disabled]="form.invalid">Записаться</button>
 
</div>