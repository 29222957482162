<div style="margin: -10px;">
    <div class="price-item">
        <div>Цены</div>
        <button mat-icon-button (click)='this.dialogRef.close();'>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-list *ngFor="let item of price">
        <mat-list-item class="price-item">
            <div style="width: 100%; display: flex; justify-content: space-between;">
            <div>
                {{item.name}}
            </div>
            <div>
                {{item.price}}
            </div>
            </div>
            
        </mat-list-item>
    </mat-list>
</div>